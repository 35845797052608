import { Box, Container, Grid } from "@mui/material";
import React from "react";
import Offer, { OfferProps } from "./offer";

export default function Offers() {
  const offers: OfferProps[] = [
    {
      img: "/business.jpeg",
      title: "FÖRETAG",
      description: `Vi erbjuder företag att komma och spela curling för 2400kr per
      bana för 1:45h med instruktör med ett maximalt antal av 10
      personer per bana.`,
      end: "flex-start",
    },
    {
      img: "/private.jpeg",
      title: "PRIVAT",
      description: `Vi erbjuder privatpersoner att komma och spela curling för 240kr per person med ett minimumpris av 1000kr för 1:45h med instruktör.`,
      end: "flex-end",
    },
    {
      img: "/students.jpeg",
      title: "STUDENTS",
      description: `Vi erbjuder studenter att komma och spela curling i 1,45h med instruktör för 180kr per person med 1000kr som minimipris för hela bokningen.`,
      eng: `We offer students to come and play curling for 180 SEK per person with a total minimum amount of 1000 SEK. This will include an instructor. The duration is 1:45h.`,
      end: "flex-start",
    },
    {
      img: "/junior.jpeg",
      title: "JUNIOR",
      description: `Juniorer upp till 21 år är mer än välkomna på träning måndagar klockan 17:45, mer info på medlemssidan.`,
      end: "flex-end",
    },
    {
      img: "/wheelchair.jpeg",
      title: "RULLSTOL",
      description: `Vi erbjuder rullstolsburna att komma och spela curling för 100kr per person i 1:45h med instruktör.`,
      end: "flex-start",
    },
    {
      img: "/skola_1.jpeg",
      title: "SKOLA",
      description: `Vi erbjuder skolor att komma och spela curling för 1500kr per 3 banor från måndag till fredag med ett maximalt antal av 30 elever.`,
      end: "flex-end",
    },
  ];

  return (
    <Box>
      {offers.map((offer) => (<Offer {...offer} key={offer.title} />))}
    </Box>
  );
}
